import React from "react";
import img01 from "../../assets/img/team/team_img01.png";
import img02 from "../../assets/img/team/team_img02.png";
import img03 from "../../assets/img/team/team_img03.png";
import img04 from "../../assets/img/team/team_img04.png";
import img05 from "../../assets/img/team/team_img05.png";
import img06 from "../../assets/img/team/team_img06.png";
import img07 from "../../assets/img/team/team_img07.png";
import img08 from "../../assets/img/team/team_img08.png";
import img09 from "../../assets/img/team/team_img09.png";
import img10 from "../../assets/img/team/team_img10.png";
import img11 from "../../assets/img/team/team_img11.png";
import img12 from "../../assets/img/team/team_img12.png";
import img13 from "../../assets/img/team/team_img13.png";
import omoonbeam from "../../assets/img/partner/moonbeam.png";
import TeamOneItem from "./TeamOneItem";
import styled from "styled-components";

const HoverImage = styled.img`
transition: transform 0.3s ease, opacity 0.3s ease;
opacity: 90%;
cursor: pointer;
max-width: 100px;

&:hover {
  opacity: 1;
  transform: scale(1.1);
}
`;

const TeamOne = () => {
  const team_members = [
    {
      src: img01,
      name: "Felipe Fatique",
      designation: "Founder & CEO",
      twitter: "https://twitter.com/f_fatique",
      linkedin: "https://www.linkedin.com/in/felipe-fatique/",
    },
    {
      src: img02,
      name: "Kledson Soares",
      designation: "Co-Founder & CTO",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://linkedin.com/in/kledsonsoares",
    },
    {
      src: img09,
      name: "Carlos Loredo",
      designation: "Co-Founder & CFO",
      twitter: "https://twitter.com/LoredoCarl91253",
      linkedin: "https://www.linkedin.com/in/carlos-loredo-2b9991264/",
    },
    {
      src: img03,
      name: "Vinicius Bedum",
      designation: "Co-Founder & CMO",
      twitter: "https://twitter.com/viniciusbedum",
      linkedin: "https://www.linkedin.com/in/viniciusbedum/",
    },
    {
      src: img05,
      name: "Pedro Moura ",
      designation: "Web Developer",
      twitter: "https://twitter.com/_bepobepo",
      linkedin: "https://www.linkedin.com/in/peedromoura/",
    },
    {
      src: img07,
      name: "Emerson Castro",
      designation: "Visual Artist & Design",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://www.linkedin.com/company/kuma-games-io",
    },
    {
      src: img08,
      name: "Deyse Emanuely",
      designation: "UX Designer",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://www.linkedin.com/company/kuma-games-io",
    },
    {
      src: img04,
      name: "Vitor Lopes",
      designation: "Game Developer",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://linkedin.com/in/josé-vítor-lima-lopes-16195b273",
    },
    {
      src: img10,
      name: "Letícia",
      designation: "3D Artist",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://www.linkedin.com/in/letícia-fatique-0a781143?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      src: img13,
      name: "Due",
      designation: "Community Manager",
      twitter: "https://x.com/_xDue",
      linkedin: "https://www.linkedin.com/in/xdue/",
    },
    {
      src: img11,
      name: "Naná",
      designation: "BizDev",
      twitter: "https://x.com/Nanazenha1",
      linkedin: "https://www.linkedin.com/in/naná-teixeira-739130298?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      src: img12,
      name: "Garbim",
      designation: "Streamer",
      twitter: "https://x.com/zezaovg",
      linkedin: "",
      youtube: "https://www.youtube.com/@garbim1",
    },
  ];

  return (
    <section id="team" className="team-area pt-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">Our Team</span>
              <h2 className="title">
                {/* The Leadership <br /> <span>Team</span> */}
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((member, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={member} />
            </div>
          ))}
        </div>
      </div>
      <div
        className="row justify-content-center"
        // style={{ marginTop: "100px" }}
      >
        <div className="col-lg-8">
          <div className="section-title text-center mb-10">
            <span className="sub-title" style={{ fontSize: "25px", marginTop: "100px", }}>
              POWERED BY
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <a
              href="https://moonbeam.network/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HoverImage
                style={{ maxWidth: "300px", width: "100%", marginBottom:'100px' }}
                src={omoonbeam}
                alt="aaa"
                className="hover-effect"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
