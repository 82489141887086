import React, { useEffect, useState } from "react";
import img01 from "../../assets/img/partner/levelup.png";
import img02 from "../../assets/img/partner/labbits.png";
import img03 from "../../assets/img/partner/ibeed.png";
import img04 from "../../assets/img/partner/gotabg.png";
import img05 from "../../assets/img/partner/picce.png";
import omoonbeam from "../../assets/img/partner/moonbeam.png";
import styled from "styled-components";
import "./TopPartners.css";

const TopPartners = () => {
  const partners_list = [
    { src: img02, title: "", url: "" },
    { src: img01, title: "", url: "https://br.leveluplatam.com/" },
    { src: img03, title: "", url: "https://ibeed.xyz/" },
    { src: img04, title: "", url: "https://gotas.social/" },
    { src: img05, title: "", url: "https://picce.xyz/" },
  ];

  const HoverImage = styled.img`
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 90%;
    cursor: pointer;
    max-width: 100px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  `;

  const HoverImage2 = styled.img`
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 90%;
    cursor: pointer;
    max-width: 100px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }

    @media (max-width: 1000px) {
      width: 80px;
    }

    @media (max-width: 500px) {
      width: 60px;
    }

    @media (max-width: 400px) {
      width: 55px;
    }

    @media (max-width: 350px) {
      width: 50px;
    }
  `;

  const Container = styled.div`

    @media (max-width: 500px) {
      display: flex,
      justifyContent: center,
      alignItems: center,
      flexDirection: column,
    }

  `;

  const Ul = styled.ul`
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20%;

    @media (max-width: 800px) {
      gap: 15%;
    }

    @media (max-width: 500px) {
      gap: 5%;
    }
  `;

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="partner-area pb-130">
      <div className="container">
        {/* ==================================================================================================  */}

        <div
          className="row justify-content-center"
          // style={{ marginTop: "100px" }}
        >
          {/* <div className="col-lg-8">
            <div className="section-title text-center mb-10">
              <span className="sub-title" style={{ fontSize: "25px" }}>
                POWERED BY
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <a
                href="https://moonbeam.network/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <HoverImage
                  style={{ maxWidth: "400px", width: "100%" }}
                  src={omoonbeam}
                  alt="aaa"
                  className="hover-effect"
                />
              </a>
            </div>
          </div> */}

          {/* ============================================================================================================= */}
          <Container>
            <div
              className="row justify-content-center"
              // style={{ marginTop: "100px" }}
            >
              <div className="col-lg-8">
                <div className="section-title text-center mb-10">
                  <span className="sub-title">Partners</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Ul>
                    {partners_list.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HoverImage2
                            src={item.src}
                            alt={item.title}
                            className="hover-effect"
                          />
                        </a>
                      </li>
                    ))}
                  </Ul>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default TopPartners;
